import { readState } from "@/__main__/app-state.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import router, { setRoute } from "@/__main__/router.mjs";
import { initSettings, setVolatileKV, showSnackbar } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import {
  addMatch,
  updateLiveGame,
  updateLoggedInAccountId,
  updateProfile,
} from "@/game-apex/actions.mjs";
import {
  EVENT_APEX_ENTER_GAME,
  EVENT_APEX_EXIT_GAME,
} from "@/game-apex/apex-client-api.mjs";
import { APEX_PLATFORMS, GAME_MODES } from "@/game-apex/constants.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition.mjs";
import OriginOverlaySnackbar from "@/game-apex/OriginOverlaySnackbar.jsx";
import {
  formatMode,
  getLastGameMode,
  ignoreModes,
} from "@/game-apex/utils.mjs";
import handleApexData from "@/game-apex/utils/handle-apex-data.mjs";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import { devLog } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

export default async () => {
  await initEvents;
  const {
    APEX_PLAYER,
    APEX_GAME_START,
    APEX_GAME_END,
    APEX_GAME_DEPLOY,
    APEX_DATA,
    APEX_IS_RUNNING,
    APEX_VIEW_BLITZ_APP,
    APEX_HAS_ORIGIN_OVERLAY,
  } = EVENTS;
  handleMessage("wndAttributes", ({ width, height, name }) => {
    setVolatileKV("wndAttributes", { width, height, name });
  });

  handleMessage(APEX_PLAYER, async (player) => {
    devLog("Received APEX_PLAYER", player);
    const { playerId, username, hardwareId } = player;
    await initSettings();
    const isOverlay = isRouteOverlay();
    updateLoggedInAccountId(playerId);
    updateProfile(
      playerId,
      {
        username,
        platformId: playerId,
        hardwareId,
      },
      isOverlay,
    );
    if (playerId && findGameSymbol() === GAME_SYMBOL_APEX && !isOverlay) {
      setRoute(`/apex/profile/${playerId}`);
    }
  });

  handleMessage(APEX_GAME_START, async (gameStart) => {
    devLog("Received APEX_GAME_START", gameStart);
    const { apexId, map, gameStartedAt, gameMode } = gameStart;
    const mode = formatMode(getLastGameMode(gameMode), gameMode);
    const match = { apexId, map, gameStartedAt, gameMode: mode };
    if (ignoreModes(mode)) return;
    const isOverlay = isRouteOverlay();

    updateLiveGame(match, isOverlay);

    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;

    if (isOverlay) {
      if (loggedInAccountId) {
        overlayRefs.destroyAd(APEX_GAME_START);
        setRoute(
          `/apex/overlay/legend-select`,
          `profileId=${loggedInAccountId}&tab=legends&mode=${GAME_MODES[mode]?.statsMode || mode}`,
        );
      }
      return;
    }

    await initSettings();
    if (loggedInAccountId) {
      setRoute(`/apex/profile/${loggedInAccountId}`);
    } else {
      setRoute("/overlays/apex");
    }
    eventBus.emit(EVENT_APEX_ENTER_GAME, {
      matchId: apexId,
      playerId: loggedInAccountId,
      gameMode: mode,
    });
  });

  handleMessage(APEX_GAME_DEPLOY, (gameDeploy) => {
    devLog("Received APEX_GAME_DEPLOY", gameDeploy);
    const { players, gameMode, gameStartedAt, apexId, map } = gameDeploy;
    const match = {
      playerMatchStats: players,
      apexId,
      map,
      gameStartedAt,
      gameMode: formatMode(getLastGameMode(gameMode), gameMode),
    };
    const isOverlay = isRouteOverlay();

    if (ignoreModes(match.gameMode) || players?.length <= 1) {
      if (isOverlay) setRoute("/apex/overlay");
      return;
    }

    updateLiveGame(match, isOverlay);

    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;

    if (isOverlay) {
      overlayRefs.destroyAd(APEX_GAME_DEPLOY);
      setRoute("/apex/overlay");
    } else if (loggedInAccountId) {
      setRoute(`/apex/profile/${loggedInAccountId}`);
    } else {
      setRoute("/overlays/apex");
    }
  });

  handleMessage(APEX_GAME_END, () => {
    devLog("Received APEX_GAME_END");
    updateLiveGame(null);
  });

  handleMessage(APEX_DATA, async (apexData) => {
    devLog("Received APEX_DATA", apexData);
    const { dataStr, diffsStr, lastMatchStr, diffRankSeasonId } = apexData;
    const isOverlay = isRouteOverlay();
    const data = JSON.parse(dataStr);
    const {
      apexId,
      map,
      gameMode: rawMode,
      gameStartedAt,
      teamId,
    } = JSON.parse(lastMatchStr);
    const {
      games_played_any_mode: gamesPlayedDiff,
      weapons: weaponsDiff,
      rank: rankDiff,
    } = JSON.parse(diffsStr);

    const {
      Enums: { eseasonflavor },
      lastgamemode,
      lastgametime,
      lastgamesquadstats,
      lastgamerank,
      lastgamecharacter,
      xp,
    } = data;

    const currentSeason = eseasonflavor.slice(-1)[0];

    await initSettings();

    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;
    const userAccountId = readState.user?.id;

    updateProfile(
      loggedInAccountId,
      {
        experiencePoints: xp,
        hoveredChampionApexId:
          typeof lastgamecharacter === "number"
            ? `said${String(lastgamecharacter).padStart(11, "0")}`
            : lastgamecharacter,
      },
      isOverlay,
    );
    if (!isOverlay) handleApexData(loggedInAccountId, data, diffRankSeasonId);

    let match;
    if (gamesPlayedDiff === 1) {
      const legends = readState.apex.meta?.legends;
      const weapons = readState.apex.meta?.weapons;
      const gameMode = formatMode(lastgamemode, rawMode);
      match = {
        season: {
          apexId: currentSeason,
          id: readState.apex.meta.seasons?.[currentSeason]?.id,
        },
        apexId,
        map: map.match(new RegExp("(?<=maps/)(.*)(?=.bsp)"))[0],
        gameMode,
        gameStartedAt,
        gameEndedAt: lastgametime,
        playerMatchStats: lastgamesquadstats
          .filter((p) =>
            p.hardwareid === APEX_PLATFORMS.PC_ORIGIN ||
            p.hardwareid === APEX_PLATFORMS.PC_STEAM
              ? p.platformuid || p.nucleusid
              : p.platformuid,
          )
          .map((p) => {
            const {
              platformuid,
              nucleusid,
              character,
              hardwareid,
              respawnsgiven,
              revivesgiven,
              damagedealt,
              survivaltime,
              ...restStats
            } = p;
            const platformId = String(platformuid || nucleusid);
            const champion =
              typeof character === "number"
                ? `said${String(character).padStart(11, "0")}`
                : character;
            const stats = {
              platformId,
              hardwareid: String(hardwareid),
              champion: { apexId: champion },
              championId: legends?.[champion]?.id,
              team: {
                placement: lastgamerank,
                apexId: teamId,
              },
              damageDone: damagedealt,
              respawnsGiven: respawnsgiven,
              revivesGiven: revivesgiven,
              survivalTime: survivaltime,
              ...restStats,
            };
            if (platformId === loggedInAccountId) {
              if (userAccountId) stats.userAccountId = parseInt(userAccountId);
              if (Object.keys(weaponsDiff).length) {
                stats.playerMatchWeaponStats = Object.entries(weaponsDiff).map(
                  ([key, value]) => {
                    return {
                      weapon: {
                        apexId: key,
                      },
                      weaponId: weapons?.[key]?.id,
                      ...value,
                    };
                  },
                );
                const fields = ["dooms", "headshots", "hits", "shots"];
                stats.playerMatchWeaponStats.forEach((w) => {
                  fields.forEach((f) => {
                    if (typeof w[f] === "number")
                      stats[f] = (stats[f] ?? 0) + w[f];
                  });
                });
              }
              if (rankDiff?.games_played === 1) {
                stats.rankedPoints = rankDiff.current_rank_score;
                stats.total_ranked_points = rankDiff.total_ranked_points;
              }
            }
            return stats;
          }),
      };

      if (!isOverlay) {
        eventBus.emit(EVENT_APEX_EXIT_GAME, {
          matchId: match.apexId,
          playerId: loggedInAccountId,
          gameMode,
        });
      } else {
        overlayRefs.destroyAd(APEX_GAME_END);
      }

      addMatch(loggedInAccountId, match, isOverlay);
      setRoute(
        isOverlay
          ? `/apex/overlay/benchmark`
          : `/apex/match/${loggedInAccountId}/${match.season.apexId}/${match.apexId}`,
        isOverlay
          ? `profileId=${loggedInAccountId}&season=${match.season.apexId}&matchId=${match.apexId}`
          : "",
        {},
        true,
      );
    }

    if (
      isOverlay &&
      !readState.apex.liveGame &&
      !match &&
      !router.route?.searchParams?.get?.("matchId")
    ) {
      setRoute(
        `/apex/overlay/benchmark`,
        `profileId=${loggedInAccountId}`,
        {},
        true,
      );
    }

    // For determining weapon id
    // const loadout = Object.entries(data.loadouts).filter(([key]) =>
    //   key.startsWith('weapon_charm_for_')
    // );
  });

  handleMessage(APEX_IS_RUNNING, (apexGame) => {
    devLog("Received APEX_IS_RUNNING", apexGame);
    if (apexGame) updateLatestPlayed(GAME_SYMBOL_APEX);
    if (
      apexGame &&
      findGameSymbol() !== GAME_SYMBOL_APEX &&
      !router.route?.currentPath?.startsWith("/account")
    ) {
      const loggedInAccountId = readState.settings?.lastLoggedInIdByGame?.apex;
      if (loggedInAccountId) {
        setRoute(`/apex/profile/${loggedInAccountId}`);
      } else {
        setRoute("/overlays/apex");
      }
    }
    if (!apexGame) updateLiveGame(null);
  });

  handleMessage(APEX_VIEW_BLITZ_APP, ({ matchId, profileId, seasonId }) => {
    if (matchId && seasonId) {
      setRoute(`/apex/match/${profileId}/${seasonId}/${matchId}`);
    } else if (profileId) {
      setRoute(`/apex/profile/${profileId}`);
    }
  });

  handleMessage(APEX_HAS_ORIGIN_OVERLAY, () => {
    devLog("Received APEX_HAS_ORIGIN_OVERLAY");
    showSnackbar({
      id: "disableOriginOverlay",
      priority: "high",
      Icon: GAME_BOX_ICONS[GAME_SYMBOL_APEX],
      Text: () => OriginOverlaySnackbar(),
      dismissable: true,
      // Removing for now because even if we disable, the user would have to restart Origin for it to apply
      // actions: [
      //   {
      //     text: ["common:disable", "Disable"],
      //     emphasis: "medium",
      //     bgColor: "var(--turq-15)",
      //     bgColorHover: "var(--turq-25)",
      //     textColor: "var(--turq)",
      //     textColorHover: "var(--turq)",
      //     onClick: () => blitzMessage(EVENTS.APEX_HAS_ORIGIN_OVERLAY),
      //   },
      // ],
    });
  });
};
