import { postData } from "@/__main__/get-data.mjs";
import { writeToPlayerLegendStats } from "@/game-apex/actions.mjs";
import { getLegends } from "@/game-apex/apex-fetch-static.mjs";
import * as API from "@/game-apex/api.mjs";
import { STATS_UPDATE_OPTIONS } from "@/game-apex/constants/constant-network.mjs";
import playerLegendsStats from "@/game-apex/fetches/player-legends-stats.mjs";
import type { EventApexData } from "@/game-apex/models/event-apex-data.mjs";
import type {
  CreatePlayerChampionSeasonStats,
  UpdatePlayerChampionStats,
} from "@/game-apex/models/player-legend-stats.mjs";
import {
  CreatePlayerChampionStatsInput,
  PlayerLegendStatsModel,
  UpdatePlayerChampionStatsInput,
} from "@/game-apex/models/player-legend-stats.mjs";
import clone from "@/util/clone.mjs";
import { devError } from "@/util/dev.mjs";

export default async function createOrUpdatePlayerLegendStat(
  {
    profileId,
    seasonId,
    seasonNumber,
    gameMode,
  }: {
    profileId: string;
    seasonId: string;
    seasonNumber: string;
    gameMode: CreatePlayerChampionSeasonStats["playerChampionSeasonStats"]["gameMode"];
  },
  stats: CharacterData,
  misc: { blitzId: string },
) {
  const { blitzId } = misc;
  const legendsMeta = await getLegends({
    shouldFetchIfPathExists: false,
    skipSafetyCheck: true,
  });
  removeUnusedLegendsData(stats);
  const playerLegendStats = await playerLegendsStats(
    { profileId, seasonId, season: seasonNumber, mode: gameMode, blitzId },
    false,
    true,
  ).catch(() => {});
  const newStats =
    playerLegendStats && !(playerLegendStats instanceof Error)
      ? clone(playerLegendStats)
      : {};
  for (const legendId in stats) {
    const championId = legendsMeta[legendId]?.id;
    if (!championId) continue;
    const playerLegendStat = playerLegendStats?.[legendId];
    const statsPayload = getStatsPayload(stats[legendId]);
    // player did not play any new matches with the legend
    if (playerLegendStat?.matchCount === statsPayload.matchCount) continue;
    const updatedStats = await postData(
      playerLegendStat
        ? API.updatePlayerChampionSeasonStats(
            updatePayload(statsPayload, {
              updateId: playerLegendStat.id,
            }),
          )
        : API.createPlayerChampionSeasonStats(
            createPayload(statsPayload, {
              gameMode,
              seasonId,
              championId,
              blitzId,
            }),
          ),
      PlayerLegendStatsModel,
      undefined,
      STATS_UPDATE_OPTIONS,
    ).catch((e) => {
      devError(
        "Apex Legends: Error updating legend stats for",
        gameMode,
        legendId,
        profileId,
        seasonNumber,
        e,
      );
    });
    if (updatedStats && !(updatedStats instanceof Error))
      newStats[legendId] = updatedStats[legendId];
  }
  writeToPlayerLegendStats({ profileId, seasonNumber, gameMode }, newStats);
}

function removeUnusedLegendsData(stats: CharacterData): void {
  // works in place
  Object.keys(stats).forEach((legendId) => {
    if (stats[legendId].games_played === 0) delete stats[legendId];
  });
}

function updatePayload(
  stats: UpdatePlayerChampionStats["playerChampionSeasonStats"],
  misc: {
    updateId: string;
  },
): UpdatePlayerChampionStats {
  const { updateId } = misc;
  return UpdatePlayerChampionStatsInput(
    {
      id: updateId,
      playerChampionSeasonStats: stats,
    },
    { removeEmpty: true },
  );
}

function createPayload(
  stats: UpdatePlayerChampionStats["playerChampionSeasonStats"],
  misc: {
    championId: string;
    blitzId: string;
    gameMode: CreatePlayerChampionSeasonStats["playerChampionSeasonStats"]["gameMode"];
    seasonId: string;
  },
) {
  const { gameMode, seasonId, championId, blitzId } = misc;
  return CreatePlayerChampionStatsInput(
    {
      playerChampionSeasonStats: {
        ...stats,
        championId,
        gameMode,
        seasonId,
        platformProfileId: blitzId,
      },
    },
    { removeEmpty: true },
  );
}

function getStatsPayload(
  stats: CharacterData["said00064207844"],
): UpdatePlayerChampionStats["playerChampionSeasonStats"] {
  const { damage_done, kills, games_played, placements_win } = stats;
  const { character_damage_done_max_single_game, revived_ally } =
    stats as AllCharacterData["said00064207844"];

  return {
    damageDone: damage_done,
    kills,
    matchCount: games_played,
    wins: placements_win,
    characterDamageDoneMaxSingleGame: character_damage_done_max_single_game,
    revives: revived_ally,
  };
}

type ModeCharacterData =
  EventApexData["stats"]["modes"]["arenas"]["characters"];
type AllCharacterData = EventApexData["stats"]["characters"];
export type CharacterData = AllCharacterData | ModeCharacterData;
