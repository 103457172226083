import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { ApexOverlaySettings } from "@/game-apex/definition.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition.mjs";
import handleMessages from "@/game-apex/handle-messages.mjs";
import { OVERLAYS } from "@/library/actions.mjs";
import clone from "@/util/clone.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import diff from "@/util/diff.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

async function initApex() {
  await initEvents;

  handleMessages();

  if (!isRouteOverlay() || findGameSymbol() === GAME_SYMBOL_APEX) {
    blitzMessage(EVENTS.APEX_READ_LIVE_GAME);
    blitzMessage(EVENTS.APEX_READ_STATE);
  }

  /**
   * Register a handleMessage listener for when overlay settings
   * are changed in-game.
   */
  if (!isRouteOverlay()) {
    handleMessage(
      EVENTS.APEX_OVERLAY_SETTINGS,
      (overlaySettings: ApexOverlaySettings) => {
        const prevOverlaySettings = clone(
          readState.settings[GAME_SHORT_NAMES[GAME_SYMBOL_APEX]][OVERLAYS],
        );

        const changes = diff(prevOverlaySettings, overlaySettings);

        const nextOverlaySettings = deepMerge(
          prevOverlaySettings,
          changes,
          false,
        );

        writeSettings(
          [GAME_SHORT_NAMES[GAME_SYMBOL_APEX], OVERLAYS],
          nextOverlaySettings,
        );
      },
    );
  }
}

export default initApex;
