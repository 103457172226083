import React from "react";
import { useTranslation } from "react-i18next";

function OriginOverlaySnackbar() {
  const { t } = useTranslation();

  return (
    <span style={{ color: "var(--shade1)" }}>
      {t(
        "common:disableOriginOverlay",
        "Please disable the Origin overlay if your mouse cursor appears on screen while playing Apex Legends.",
      )}
    </span>
  );
}

export default OriginOverlaySnackbar;
