import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { isPersistent, isVolatile, MAX_TIME } from "@/__main__/constants.mjs";
import getData, { postData, readData } from "@/__main__/get-data.mjs";
import { addFavoriteGame, writeSettings } from "@/app/actions.mjs";
import { mergeLocalWithApi, upsertLocalData } from "@/app/util.mjs";
import noopModel from "@/data-models/no-op.mjs";
import * as API from "@/game-apex/api.mjs";
import { LIFETIME_SEASON } from "@/game-apex/constants.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition-symbol.mjs";
import { ApexCreateMatchModel } from "@/game-apex/models/create-match.mjs";
import { ApexMatchModel } from "@/game-apex/models/match.mjs";
import type { ApexPlayer } from "@/game-apex/models/player.mjs";
import {
  ApexPlayerModel,
  UpdateApexPlayerModel,
} from "@/game-apex/models/player.mjs";
import type { PlayerLegendStats } from "@/game-apex/models/player-legend-stats.mjs";
import type { ApexPlayerWeaponStatsMap } from "@/game-apex/models/player-weapon-stats.mjs";
import { getCurrentSeason } from "@/game-apex/utils.mjs";
import clone from "@/util/clone.mjs";
import deepEqual from "@/util/deep-equal.mjs";

export function updateLoggedInAccountId(profileId: string) {
  if (!profileId || profileId === readState.settings.lastLoggedInIdByGame.apex)
    return;
  writeSettings(["lastLoggedInIdByGame", "apex"], profileId);
}

export async function updateProfile(profileId, update: ApexPlayer, isOverlay) {
  if (!profileId) return;
  await readData(["apex", "profiles", profileId]);
  const profile = clone(readState.apex.profiles[profileId]);
  const newProfile = {
    ...(profile || {}),
    ...update,
    updatedAt: new Date(),
  };
  newProfile[isPersistent] = MAX_TIME;
  writeState.apex.profiles[profileId] = newProfile;
  const {
    platformId,
    experiencePoints,
    hoveredChampionApexId,
    hardwareId,
    username,
  } = newProfile;

  if (profileId && hoveredChampionApexId && username) {
    writeSettings(["loggedInAccounts", "apex", profileId], {
      hoveredChampionApexId,
      username,
      profileId,
    });
    addFavoriteGame(GAME_SYMBOL_APEX);
  }

  if (
    !isOverlay &&
    platformId &&
    typeof experiencePoints === "number" &&
    hoveredChampionApexId &&
    hardwareId &&
    username
  ) {
    const platformProfile = UpdateApexPlayerModel(
      {
        experiencePoints,
        hoveredChampionApexId,
        hardwareProfiles: [
          {
            hardwareId,
            username,
          },
        ],
      },
      { isPostValidation: true, removeEmpty: true },
    );

    upsertLocalData({
      curValue: profile,
      newValue: platformProfile,
      updateKey: "platformProfile",
      writeStatePath: ["apex", "profiles", platformId],
      getQuery: API.getPlayer({ platformId }),
      getOptions: { networkBackOffTime: 0 },
      createQuery: API.createPlayer({
        platformProfile: {
          ...platformProfile,
          platformId,
          profile: {
            apexId: platformId,
          },
        },
      }),
      updateQuery: API.updatePlayer,
      shouldUpdate: (curValue, newValue) => {
        const {
          experiencePoints,
          hoveredChampionApexId,
          hardwareId,
          username,
        } = curValue;
        const curValueFormatted = {
          experiencePoints,
          hoveredChampionApexId,
          hardwareProfiles: [
            {
              hardwareId,
              username,
            },
          ],
        };
        return !deepEqual(
          UpdateApexPlayerModel(
            { ...curValueFormatted },
            { isPostValidation: true, removeEmpty: true },
          ),
          newValue,
        );
      },
      model: ApexPlayerModel,
    });
  }
}

export function addMatchToState(match) {
  const oldMatch = clone(readState.apex.matches?.[match.apexId]);
  const newMatch = {
    ...(oldMatch || {}),
    ...match,
    [isPersistent]: MAX_TIME,
  };
  writeState.apex.matches[match.apexId] = newMatch;
  return newMatch;
}

export async function addMatch(profileId, match, isOverlay) {
  if (!profileId || !match) return;
  const allSeasons = readState.apex.meta.seasons;
  const currentSeason = getCurrentSeason(allSeasons);
  const seasons = [
    LIFETIME_SEASON.apexSeasonNumber,
    currentSeason?.apexSeasonNumber,
  ];
  const modes = ["ALL", match.gameMode];
  for (const season of seasons) {
    if (!season) continue;
    for (const mode of modes) {
      await readData(["apex", "matchlists", profileId, season, mode]);
      let matchlist = clone(
        readState.apex.matchlists[profileId]?.[season]?.[mode] ?? [],
      );

      if (!Array.isArray(matchlist)) matchlist = [];

      const { id, apexId, gameStartedAt } = match;
      const matchlistMatch = {
        id,
        apexId,
        gameStartedAt,
        season: match.season,
      };

      matchlist.unshift(matchlistMatch);
      matchlist.sort((a, b) => b.gameStartedAt - a.gameStartedAt);
      matchlist[isPersistent] = MAX_TIME;
      writeState.apex.matchlists[profileId][season][mode] = matchlist;
    }
  }
  const newMatch = addMatchToState(match);
  if (
    !isOverlay &&
    newMatch?.playerMatchStats &&
    newMatch.id &&
    readState.apex.meta.seasons
  ) {
    const seasonId = readState.apex.meta.seasons?.[newMatch.season.apexId]?.id;
    const [playerStats, playerWeaponStats] = newMatch.playerMatchStats.reduce(
      (acc, player) => {
        const {
          playerMatchWeaponStats,
          damageDone,
          platformId,
          championId,
          team,
          respawnsGiven,
          revivesGiven,
          survivalTime,
          assists,
          deaths,
          headshots,
          hits,
          kills,
          knockdowns,
          shots,
          rankedPoints,
          total_ranked_points,
          userAccountId,
        } = player;
        const profile = readState.apex.profiles[platformId];
        if (team.apexId && profile?.id && championId)
          acc[0].push({
            matchId: newMatch.id,
            championId,
            seasonId,
            damageDone,
            platformProfileId: profile?.id,
            userAccountId,
            respawnsGiven,
            revivesGiven,
            survivalTime,
            assists,
            deaths,
            headshots,
            hits,
            kills,
            knockdowns,
            shots,
            rankedPoints,
            total_ranked_points,
            team: {
              apexId: team.apexId,
              placement: team.placement,
              seasonId,
            },
          });
        if (playerMatchWeaponStats && profile?.id) {
          for (const weapon of playerMatchWeaponStats) {
            const { damageDone, headshots, hits, kills, shots, weaponId } =
              weapon;
            acc[1].push({
              matchId: newMatch.id,
              seasonId,
              platformProfileId: profile?.id,
              damageDone,
              headshots,
              hits,
              kills,
              shots,
              weaponId,
            });
          }
        }
        return acc;
      },
      [[], []],
    );
    return postData(
      API.updateMatch({
        id: newMatch.id,
        playerStats,
        playerWeaponStats,
      }),
      noopModel,
      ["volatile", "updateApexMatch"],
      { skipLoadingPlaceholder: true },
    );
  }
}

export async function updateLiveGame(match, isOverlay) {
  let liveGame = clone(readState.apex.liveGame);

  if (match) {
    liveGame = {
      ...(liveGame || {}),
      ...match,
    };

    liveGame[isVolatile] = true;
  } else {
    liveGame = null;
  }

  writeState.apex.liveGame = liveGame;
  writeState.apex.liveGameTime = new Date();

  if (match && !isOverlay) {
    await readData(["apex", "matches", match.apexId]);

    if (
      !readState.apex.matches?.[match.apexId] &&
      readState.apex.meta.seasons
    ) {
      const currentSeason = getCurrentSeason(readState.apex.meta.seasons);
      const seasonId = currentSeason?.id;

      const newMatch = clone(match);
      newMatch[isPersistent] = true;
      writeState.apex.matches[match.apexId] = newMatch;

      const { gameMode, gameStartedAt, map, apexId } = match;

      postData(
        API.createMatch({
          match: {
            apexId,
            seasonId,
            map,
            gameMode,
            gameStartedAt: new Date(gameStartedAt * 1000),
          },
        }),
        ApexCreateMatchModel,
        ["apex", "matches", match.apexId],
        { mergeFn: mergeLocalWithApi, skipLoadingPlaceholder: true },
      ).catch((err) => {
        if (err?.message?.includes("apexId has already been taken")) {
          getData(
            API.getMatchId({
              seasonId,
              apexId,
            }),
            ApexMatchModel,
            ["apex", "matches", apexId],
            {
              skipSafetyCheck: true,
              expiryTime: MAX_TIME,
              mergeFn: mergeLocalWithApi,
            },
          );
        }
      });
    }

    if (match?.playerMatchStats) {
      for (const player of match.playerMatchStats) {
        const { platformId, hardwareId, username, apexId } = player;
        const cachedPlayer = readState.apex.profiles[platformId];

        // eslint-disable-next-line no-await-in-loop
        await upsertLocalData({
          curValue: cachedPlayer,
          writeStatePath: ["apex", "profiles", platformId],
          getQuery: API.getPlayer({ platformId }),
          createQuery: API.createPlayer({
            platformProfile: {
              platformId,
              profile: {
                apexId,
              },
              hardwareProfiles: [
                {
                  hardwareId,
                  username,
                },
              ],
            },
          }),
          model: ApexPlayerModel,
        });
      }
    }
  }
}

export function writeToPlayerWeaponStats(
  {
    profileId,
    seasonNumber,
    gameMode,
  }: { profileId: string; seasonNumber: string; gameMode: string },
  stats: ApexPlayerWeaponStatsMap,
) {
  writeState.apex.playerWeaponStats[profileId][seasonNumber][gameMode] = stats;
}

export function writeToPlayerLegendStats(
  {
    profileId,
    seasonNumber,
    gameMode,
  }: { profileId: string; seasonNumber: string; gameMode: string },
  stats: PlayerLegendStats,
) {
  writeState.apex.playerLegendStats[profileId][seasonNumber][gameMode] = stats;
}
