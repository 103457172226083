import { postData } from "@/__main__/get-data.mjs";
import { writeToPlayerWeaponStats } from "@/game-apex/actions.mjs";
import { getWeapons } from "@/game-apex/apex-fetch-static.mjs";
import * as API from "@/game-apex/api.mjs";
import { STATS_UPDATE_OPTIONS } from "@/game-apex/constants/constant-network.mjs";
import playerWeaponStats from "@/game-apex/fetches/player-weapon-stats.mjs";
import type { EventApexData } from "@/game-apex/models/event-apex-data.mjs";
import type {
  ApexPlayerWeaponStats,
  CreatePlayerWeaponStats,
  UpdatePlayerWeaponStats,
} from "@/game-apex/models/player-weapon-stats.mjs";
import {
  ApexPlayerWeaponStatsModel,
  CreatePlayerWeaponStatsInput,
  UpdatePlayerWeaponStatsInput,
} from "@/game-apex/models/player-weapon-stats.mjs";
import clone from "@/util/clone.mjs";
import { devError } from "@/util/dev.mjs";

export default async function createOrUpdatePlayerWeaponStat(
  {
    profileId,
    seasonId,
    seasonNumber,
    gameMode,
  }: {
    profileId: string;
    seasonId: string;
    seasonNumber: string;
    gameMode: CreatePlayerWeaponStats["playerWeaponSeasonStats"]["gameMode"];
  },
  stats: WeaponData,
  misc: { blitzId: string },
) {
  const { blitzId } = misc;
  const weaponsMeta = await getWeapons({
    shouldFetchIfPathExists: false,
    skipSafetyCheck: true,
  });
  removeUnusedWeapons(stats);
  const weaponStats = await playerWeaponStats(
    { blitzId, profileId, seasonId, season: seasonNumber, mode: gameMode },
    false,
    true,
  ).catch(() => {});
  const newWeaponStats = weaponStats ? clone(weaponStats) : {};
  for (const weaponId in stats) {
    if (!weaponsMeta[weaponId]?.id) continue;
    const statsPayload = getStatsPayload(stats[weaponId]);
    const playerWeaponStat = weaponStats?.[weaponId];
    if (playerWeaponStat && isEqualStats(statsPayload, playerWeaponStat))
      continue;
    const updatedStats = await postData(
      playerWeaponStat
        ? API.updatePlayerWeaponSeasonStats(
            updatePayload(statsPayload, { updateId: playerWeaponStat.id }),
          )
        : API.createPlayerWeaponSeasonStats(
            createPayload(statsPayload, {
              blitzId,
              seasonId,
              gameMode,
              weaponId: weaponsMeta[weaponId].id,
            }),
          ),
      ApexPlayerWeaponStatsModel,
      undefined,
      STATS_UPDATE_OPTIONS,
    ).catch((e) => {
      devError(
        "Apex Legends: Error updating weapon stats for",
        gameMode,
        weaponId,
        profileId,
        seasonNumber,
        playerWeaponStat,
        statsPayload,
        e,
      );
    });
    if (updatedStats && !(updatedStats instanceof Error)) {
      newWeaponStats[weaponId] = updatedStats[weaponId];
    }
  }

  writeToPlayerWeaponStats(
    { profileId, seasonNumber, gameMode },
    newWeaponStats,
  );
}

function updatePayload(
  stats: UpdatePlayerWeaponStats["playerWeaponSeasonStats"],
  misc: { updateId: string },
): UpdatePlayerWeaponStats {
  const { updateId } = misc;

  return UpdatePlayerWeaponStatsInput(
    {
      id: updateId,
      playerWeaponSeasonStats: stats,
    },
    {
      removeEmpty: true,
    },
  );
}

function createPayload(
  stats: UpdatePlayerWeaponStats["playerWeaponSeasonStats"],
  misc: {
    weaponId: string;
    blitzId: string;
    gameMode: CreatePlayerWeaponStats["playerWeaponSeasonStats"]["gameMode"];
    seasonId: string;
  },
): CreatePlayerWeaponStats {
  const { weaponId, blitzId, gameMode, seasonId } = misc;
  return CreatePlayerWeaponStatsInput(
    {
      playerWeaponSeasonStats: {
        weaponId,
        gameMode,
        seasonId,
        platformProfileId: blitzId,
        ...stats,
      },
    },
    {
      removeEmpty: true,
    },
  );
}

function getStatsPayload(
  stats: WeaponData["said01475792313"] | ApexPlayerWeaponStats,
): UpdatePlayerWeaponStats["playerWeaponSeasonStats"] {
  const { damage_done, kills, headshots } =
    stats as WeaponData["said01475792313"];
  const { damageDone } = stats as ApexPlayerWeaponStats;
  const { shots, hits } = stats as AllWeaponData["said01475792313"];
  return {
    headshots,
    damageDone: damage_done ?? damageDone,
    kills,
    hits,
    shots,
  };
}

function isUnusedWeapon(stats: WeaponData["said01475792313"]) {
  for (const key in stats) {
    if (typeof stats[key] === "number" && stats[key] !== 0) return false;
  }
  return true;
}

function removeUnusedWeapons(stats: WeaponData) {
  // deletes in-place
  Object.keys(stats).forEach((weaponId) => {
    if (isUnusedWeapon(stats[weaponId])) delete stats[weaponId];
  });
}

function isEqualStats(
  stats1: UpdatePlayerWeaponStats["playerWeaponSeasonStats"],
  stats2: ApexPlayerWeaponStats,
): boolean {
  for (const key in stats1) {
    if (stats2[key] !== stats1[key]) return false;
  }
  return true;
}

type ModeWeaponData = EventApexData["stats"]["modes"]["arenas"]["weapons"];
type AllWeaponData = EventApexData["stats"]["weapons"];
export type WeaponData = AllWeaponData | ModeWeaponData;
