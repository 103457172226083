import { postData } from "@/__main__/get-data.mjs";
import * as API from "@/game-apex/api.mjs";
import { APEX_STATS_EXPIRY } from "@/game-apex/constants/constant-network.mjs";
import playerSeasonStats from "@/game-apex/fetches/player-season-stats.mjs";
import type { EventApexData } from "@/game-apex/models/event-apex-data.mjs";
import type {
  CreatePlayerSeasonStatsInput,
  UpdateApexPlayerStats,
} from "@/game-apex/models/player-stats.mjs";
import {
  ApexPlayerStatsModel,
  CreatePlayerSeasonStatsInputValidator,
  UpdateApexPlayerStatsValidator,
} from "@/game-apex/models/player-stats.mjs";
import { devError } from "@/util/dev.mjs";

export default async function createOrUpdatePlayerSeasonStat(
  {
    profileId,
    seasonId,
    seasonNumber,
    gameMode,
  }: {
    profileId: string;
    seasonId: string;
    seasonNumber: string;
    gameMode: CreatePlayerSeasonStatsInput["playerSeasonStats"]["gameMode"];
  },
  stats: PlayerStats,
  misc: MiscData,
): Promise<void> {
  const statsPayload = getStatsPayload(stats);
  if (statsPayload.matchCount === 0) return;
  // Check if the player has a season stat for this game mode
  const playerSeasonStat = await playerSeasonStats({
    blitzId: misc.blitzId,
    mode: gameMode,
    profileId,
    season: seasonNumber,
    seasonId,
  }).catch(() => {
    // Silently catch the error, this means that the BE does not have player stats and we need to create it
  });
  const hasStats = playerSeasonStat && !(playerSeasonStat instanceof Error);
  if (hasStats && statsPayload.matchCount === playerSeasonStat.matchCount)
    return;
  // Create or update the player season stat
  await postData(
    hasStats
      ? API.updatePlayerSeasonStats(
          UpdateApexPlayerStatsValidator(
            updatePayload(statsPayload, {
              ...misc,
              gameMode,
              seasonId,
              updateId: playerSeasonStat.id,
            }),
          ),
        )
      : API.createPlayerSeasonStats(
          CreatePlayerSeasonStatsInputValidator(
            createPayload(statsPayload, {
              ...misc,
              gameMode,
              seasonId,
            }),
          ),
        ),
    ApexPlayerStatsModel,
    ["apex", "playerStats", profileId, seasonNumber, gameMode],
    {
      skipLoadingPlaceholder: true,
      expiryTime: APEX_STATS_EXPIRY,
    },
  ).catch((e) => {
    devError(
      "Apex Legends: Error updating season stats for",
      gameMode,
      profileId,
      seasonNumber,
      e,
    );
  });
}

function createPayload(
  stats: UpdateApexPlayerStats["playerSeasonStats"],
  misc: MiscData & {
    gameMode: CreatePlayerSeasonStatsInput["playerSeasonStats"]["gameMode"];
    seasonId: string;
  },
): CreatePlayerSeasonStatsInput {
  const { gameMode, seasonId, blitzId } = misc;
  const playerSeasonStats = {
    ...stats,
    gameMode,
    platformProfileId: blitzId,
    seasonId,
  };
  if (misc.ranked) Object.assign(playerSeasonStats, misc.ranked);
  return {
    playerSeasonStats,
  };
}

function updatePayload(
  stats: UpdateApexPlayerStats["playerSeasonStats"],
  misc: MiscData & {
    gameMode: CreatePlayerSeasonStatsInput["playerSeasonStats"]["gameMode"];
    seasonId: string;
    updateId: string;
  },
): UpdateApexPlayerStats {
  const { updateId } = misc;
  const payload = {
    id: updateId,
    playerSeasonStats: stats,
  };
  if (misc.ranked) Object.assign(payload.playerSeasonStats, misc.ranked);
  return payload;
}

function getStatsPayload(
  stats: PlayerStats,
): UpdateApexPlayerStats["playerSeasonStats"] {
  return {
    assists: stats.assists,
    damageDone: stats.damage_done,
    deaths: stats.deaths,
    kills: stats.kills,
    matchCount: stats.games_played,
    wins: stats.placements_win,
  };
}

export function getStatsFromData(
  stats:
    | ModeAndSeasonData
    | RankedSeasonData
    | AllModeSeasonData
    | ModeAllSeasonData
    | AllModeAllSeasonData,
): PlayerStats {
  return {
    assists: stats.assists,
    damage_done: stats.damage_done,
    deaths: stats.deaths,
    kills: stats.kills,
    placements_win: stats.placements_win,
    games_played: stats.games_played,
  };
}

export function mergeStats(
  stats1: PlayerStats,
  stats2: PlayerStats,
): PlayerStats {
  // this can be concise using by looping, keeping it as is for ease of understanding
  return {
    assists: stats1.assists + stats2.assists,
    damage_done: stats1.damage_done + stats2.damage_done,
    deaths: stats1.deaths + stats2.deaths,
    kills: stats1.kills + stats2.kills,
    placements_win: stats1.placements_win + stats2.placements_win,
    games_played: stats1.games_played + stats2.games_played,
  };
}

export type ModeAndSeasonData =
  EventApexData["stats"]["modes"]["arenas"]["seasons"]["said00048930450"];
export type AllModeSeasonData =
  EventApexData["stats"]["seasons"]["said00048930450"];
export type ModeAllSeasonData = EventApexData["stats"]["modes"]["arenas"];
export type AllModeAllSeasonData = EventApexData["stats"];
export type RankedSeasonData =
  EventApexData["stats"]["rankedperiods"]["said00747315762"];

export type MiscData = {
  blitzId: string;
  ranked?: {
    firstSplitRankedPoints?: number;
    rankedPoints: number;
  };
};

export type PlayerStats = {
  assists: number;
  damage_done: number;
  deaths: number;
  kills: number;
  placements_win: number;
  games_played: number;
};
